/*global $*/
/*eslint no-undef: "error"*/
export default class OrderButton {
    clasItem = 'order-button--item'
    clasBlock = 'order-button--block'
    clasButton = 'order-button--button'

    classButtonHidden = 'button-hidden'

    constructor () {
        let self = this

        $(`.${self.clasBlock}`).click(function () {
            let isCheck = $(this).closest(`.${self.clasItem}`).find(`input`).is(':checked')

            if (!isCheck) {
                $(`.${self.clasButton}[data-block='1']`).addClass(self.classButtonHidden)
                $(`.${self.clasButton}[data-block='2']`).removeClass(self.classButtonHidden)
            } else {
                $(`.${self.clasButton}[data-block='1']`).removeClass(self.classButtonHidden)
                $(`.${self.clasButton}[data-block='2']`).addClass(self.classButtonHidden)
            }
        })
    }
}
