/*global $*/
/*eslint no-undef: "error"*/
export default class MenuCatalog {
    classBlock = 'menu-catalog--block'
    classButton = 'menu-catalog--button'

    classBlockOpen = 'menu-catalog__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
