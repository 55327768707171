<template lang="pug">
.form(:class="'form-' + formType")
  .form__block(v-if="formType === 'online'")
    h2.center(v-show="!sendForm" v-html="formTitle")
    h2.center(v-show="sendForm" v-html="formSuccessTitle")

    .form__fields(v-show="!sendForm")
      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-user")

      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-phone")

      .form__field
        label.input-textarea.input-textarea-gray.input-textarea-height-small
          textarea.input-textarea__input(
            v-model="fieldText"
            :placeholder="formFieldTextPlaceholder"
          )

    .form__consent(v-show="!sendForm")
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button(v-show="!sendForm")
      .button.button-background-orange.button-color-white.button-height-50(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")
    .form__success(v-show="sendForm")
      p(v-html="formSuccessText")

  .form__block(v-if="formType === 'sub'")
    .form__fields(v-show="!sendForm")
      .form__field
        label.input-text.input-text-icon
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-email")

      .form__field
        .button.button-background-orange.button-color-white.button-height-50(
          :class="{'button-disable': v$.$invalid}"
          @click="getSend()"
        )
          .button__text(v-html="formButtonName")
    .form__success(v-show="sendForm")
      p(v-html="formSuccessText")

  .form__block(v-if="(formType === 'phone') || formType === 'order'")
    h2.center(v-show="!sendForm" v-html="formTitle")
    h2.center(v-show="sendForm" v-html="formSuccessTitle")

    .form__fields(v-show="!sendForm")
      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-user")

      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-phone")

    .form__consent(v-show="!sendForm")
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button(v-show="!sendForm")
      .button.button-background-orange.button-color-white.button-height-50(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")

    .form__success(v-show="sendForm")
      p.center(v-html="formSuccessText")

  .form__block(v-if="formType === 'product'")
    h2.center(v-show="!sendForm" v-html="formTitle")
    h2.center(v-show="sendForm" v-html="formSuccessTitle")

    .form__fields(v-show="!sendForm")
      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-user")

      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-phone")

      .form__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-email")

    .form__consent(v-show="!sendForm")
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button(v-show="!sendForm")
      .button.button-background-orange.button-color-white.button-height-50(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")

    .form__success(v-show="sendForm")
      p.center(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formType",
    "formTitle",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        if ((this.formType === 'phone') || (this.formType === 'order')) {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldEmail = this.fieldPhone = ''
        }

        if (this.formType === 'sub') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldEmail,
                value: this.fieldEmail
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldEmail = ''
        }

        if (this.formType === 'product') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldEmail,
                value: this.fieldEmail
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldEmail = ''
        }

        if (this.formType === 'online') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldText,
                value: this.fieldText
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files
          })

          this.fieldName = this.fieldPhone = this.fieldText = ''
        }

        setTimeout(() => {
          this.sendForm = !this.sendForm
          $('.is-close-btn').click()
        }, 3500)
      }
    }
  },

  validations () {

    if (this.formType === 'sub') {
      return {
        fieldEmail: {
          required,
          email,
          minLength: minLength(8),
          maxLength: maxLength(30)
        },

        consent: {
          required
        }
      }
    }

    if ((this.formType === 'phone') || (this.formType === 'order') || (this.formType === 'online') || (this.formType === 'product')) {
      return {
        fieldPhone: {
          required,
          minLength: minLength(18),
          maxLength: maxLength(18)
        },

        consent: {
          required
        }
      }
    }
  }
}
</script>
