/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonCount {
    classBlock = 'button-count--block'
    classItem = 'button-count--item'
    classButton = 'button-count--button'
    classTotal = 'button-count--total'
    classCount = 'button-count--count'
    classChange = 'button-count--change'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let step = parseInt($(this).closest(`.${self.classItem}`).data('step'))
            let objTotal = $(this).closest(`.${self.classBlock}`).find(`.${self.classTotal}`)
            let type = $(this).data('type')
            let count = parseInt(objTotal.val())

            if (type === 'minus') {
                if (count > step) {
                    count -= step
                }
            } else {
                count += step
            }

            $(this).closest(`.${self.classBlock}`).find(`.${self.classCount}`).text(count)
            objTotal.val(count)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classChange}`).click()

            console.log(step)
        })
    }
}
