import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createSlide () {
        return new Swiper('#slider--slide', {
            autoplay: {
                delay: 7500,
            },
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slide-next',
                prevEl: '.slider--slide-prev',
            },
            pagination: {
                el: '.swiper-pagination--slide',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createDays () {
        return new Swiper('#slider--days', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--days-next',
                prevEl: '.slider--days-prev',
            },
            pagination: {
                el: '.swiper-pagination--days',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createAnalogues () {
        return new Swiper('#slider--analogues', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--analogues-next',
                prevEl: '.slider--analogues-prev',
            },
            pagination: {
                el: '.swiper-pagination--days',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createSale () {
        return new Swiper('#slider--sale', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--sale-next',
                prevEl: '.slider--sale-prev',
            },
            pagination: {
                el: '.swiper-pagination--sale',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createSection () {
        return new Swiper('#slider--section', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--section-next',
                prevEl: '.slider--section-prev',
            },
            pagination: {
                el: '.swiper-pagination--section',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 3
                },
                900: {
                    slidesPerView: 4
                },
                991: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
                1240: {
                    slidesPerView: 5
                }
            }
        })
    }

    createBrand () {
        return new Swiper('#slider--brand', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--brand-next',
                prevEl: '.slider--brand-prev',
            },
            pagination: {
                el: '.swiper-pagination--brand',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 2
                },
                360: {
                    slidesPerView: 2
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 3
                },
                514: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 3
                },
                900: {
                    slidesPerView: 4
                },
                991: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
                1240: {
                    slidesPerView: 5
                }
            }
        })
    }

    createNews () {
        return new Swiper('#slider--news', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--news-next',
                prevEl: '.slider--news-prev',
            },
            pagination: {
                el: '.swiper-pagination--news',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createReviewVideo () {
        return new Swiper('#slider--review-video', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--review-video-next',
                prevEl: '.slider--review-video-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                }
            }
        })
    }

    createReviewVideoTop () {
        return new Swiper('#slider--review-video-top', {
            /*
            autoplay: {
                delay: 7500,
            },
            */
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--review-video-top-next',
                prevEl: '.slider--review-video-top-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-video-top',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slide') != null) {
            this.createSlide()
        }

        if(document.getElementById('slider--days') != null) {
            this.createDays()
        }

        if(document.getElementById('slider--analogues') != null) {
            this.createAnalogues()
        }

        if(document.getElementById('slider--sale') != null) {
            this.createSale()
        }

        if(document.getElementById('slider--section') != null) {
            this.createSection()
        }

        if(document.getElementById('slider--brand') != null) {
            this.createBrand()
        }

        if(document.getElementById('slider--news') != null) {
            this.createNews()
        }

        if(document.getElementById('slider--review-video') != null) {
            this.createReviewVideo()
        }

        if(document.getElementById('slider--review-video-top') != null) {
            this.createReviewVideoTop()
        }
    }
}
