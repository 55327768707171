/*global $*/
/*eslint no-undef: "error"*/
export default class PhonePopup {
    classBlock = 'phone-popup--block'
    classButton = 'phone-popup--button'

    classBlockOpen = 'phone-button-open'
    // classBackground = 'background-none-640'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            // $(`body`).toggleClass(self.classBackground)
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
