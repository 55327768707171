/*global $*/
/*eslint no-undef: "error"*/
export default class Filter {
    classBlock = 'filter--block'
    classButton = 'filter--button'
    classBody = 'body'

    classOpen = 'filter-open'
    classBodyOverflow = 'overflow-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`${self.classBody}`).toggleClass(self.classBodyOverflow)
            $(`.${self.classBlock}`).toggleClass(self.classOpen)
        })
    }
}
