<template lang="pug">
.form-material
  .form-material__block(v-show="!sendForm")
    h2.center(v-html="formTitle")

    .form-material__fields
      .form-material__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-user")

      .form-material__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-phone")

      .form-material__field
        label.input-text.input-text-gray.input-text-icon
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-email")

    .form-material__files
      .form-files
        .form-files__item
          input(
            type="file"
            name="files"
            ref="files"
            id="filesMaterial"
            multiple
            style="display: none;"
            @change="onFileChange"
          )

          label.form-files__label(
            v-html="formFieldFilesButton"
            for="filesMaterial"
          )

        .form-files__item
          label.form-files__result(v-html="fileResult")

    .form-material__text
      label.input-textarea.input-textarea-gray.input-textarea-height-small
        textarea.input-textarea__input(
          v-model="fieldText"
          :placeholder="formFieldTextPlaceholder"
        )

    .form-material__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form-material__button
      .button.button-background-orange.button-color-white.button-height-50(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__text(v-html="formButtonName")

  .form-material__block(v-show="sendForm")
    h2.center(v-show="sendForm" v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormMaterial',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formTitle",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldText: '',
      consent: ['consent'],
      sendForm: false,

      formFieldFilesButton: 'Выберите файлы',
      fileResult: 'Файлы не выбраны',
      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldEmail = this.fieldPhone = this.fieldText = ''
        this.files = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
          $(`.is-close-btn`).click()
        }, 4500)
      }
    }
  },

  validations () {
    return {
      fieldEmail: {
        required,
        email,
        minLength: minLength(8),
        maxLength: maxLength(30)
      },
      consent: {
        required
      }
    }
  }
}

</script>
