/*global $*/
/*eslint no-undef: "error"*/
export default class InputPayment {
    classBlock = 'input-payment--block'
    classBlockOpen = 'input-payment-open'
    classButton = 'input-payment--button'
    classItem = 'input-payment--item'
    className = 'input-payment--name'
    classInput = 'input-payment--input'

    classFieldAddress = 'order__field-address'
    classFieldAddressHidden = 'order__field-address-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })

        $(`.${self.classItem}`).click(function () {
            let type = $(this).closest(`.${self.classBlock}`).data('block')
            let name = $(this).data('name')
            let objBlock = $(this).closest(`.${self.classBlock}`)
            let number = $(this).data('number')

            objBlock.removeClass(self.classBlockOpen)
            objBlock.find(`.${self.className}`).text(name)

            if (type === "delivery") {
                if (number === 2) {
                    console.log('work')
                    $(`.${self.classFieldAddress}`).removeClass(self.classFieldAddressHidden)
                } else {
                    $(`.${self.classFieldAddress}`).find(`input`).val('')
                    $(`.${self.classFieldAddress}`).addClass(self.classFieldAddressHidden)
                }
            }
        })
    }
}
