/*global $*/
/*eslint no-undef: "error"*/
export default class HeaderCatalog {
    classBlock = 'header-catalog--block'
    classButton = 'header-catalog--button'

    classHeaderCatalogOpen = 'header-bottom__catalog-open'
    classBackground = 'background'
    classButtonOpen = 'button-catalog-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`body`).toggleClass(self.classBackground)
            $(`.${self.classBlock}`).toggleClass(self.classHeaderCatalogOpen)
            $(this).toggleClass(self.classButtonOpen)
        })

        $(`html`).on(`click`, `body.background`, function (e) {
            if (e.target.className === self.classBackground) {
                $(`body`).removeClass(self.classBackground)
                $(`.${self.classBlock}`).removeClass(self.classHeaderCatalogOpen)
                $(this).removeClass(self.classButtonOpen)
            }
        })
    }
}
